<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
              class="clear-992"
              :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <UserInfo/>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.dashboard"
                             class="detail-page__head-back"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_goToDashboard'])"></div>
                  <LinkBack
                          :value="$t('common_goToDashboard.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['scanBarcode_scanBarcode'])"></div>
              {{$t('scanBarcode_scanBarcode.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--66 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['scanBarcode_scanId'])"></div>
                <SearchInput
                    :type="'text'"
                    :label="'Scan Id'"
                    v-model="barcode"
                    @submit="scan($event, 'barcode')"
                    @keyup.native="scan($event, 'barcode', true)"
                />
                <div class="color-red pt-2"
                     v-if="barcodeErrorItem !== ''"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['scanBarcode_notFound'])"></div>
                  {{"'" + barcodeErrorItem + "'"}} {{$t('scanBarcode_notFound.localization_value.value')}}
                </div>
              </div>
            </div>
            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--66 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['scanBarcode_scanTrackNumber'])"></div>
                <SearchInput
                    :type="'text'"
                    :label="'Scan Track'"
                    v-model="trackNum"
                    @submit="scan($event, 'track')"
                    @keyup.native="scan($event, 'track', true)"
                />
                <div class="color-red pt-2"
                     v-if="trackNumErrorItem !== ''"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['scanBarcode_notFound'])"></div>
                  {{"'" + trackNumErrorItem + "'"}} {{$t('scanBarcode_notFound.localization_value.value')}}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.dashboard"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import UserInfo from "./components/UserInfo/UserInfo";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import SearchInput from "../../../../../UI/inputs/SearchInput/SearchInput";
  import {FBMMixinsHelper} from "../../../../../../mixins/FBMMixins/FBMMixins";
  import {expressMixins} from "../../../../../../mixins/expressMixins/expressMixin";
  import {consolidationMixin} from "../../../../../../mixins/consolidationMixins/consolidationMixin";
  import {ordersInboundMixin} from "../../../../../../mixins/ordersInbound/ordersInboundMixin";

  export default {
    name: "ScanBarcodeAdmin",

    components: {
      SearchInput,
      CardLeftBlock,
      UserInfo,
      CardRightBlock,
      LinkBack,
    },

    mixins: [
      FBMMixinsHelper,
      expressMixins,
      consolidationMixin,
      ordersInboundMixin
    ],

    data() {
      return {
        barcode: '',
        barcodeErrorItem: '',
        trackNum: '',
        trackNumErrorItem: '',
      }
    },

    methods: {
      scan(e, type, checkPressedKey = false) {
        console.log(e);
        if (checkPressedKey && e.code !== 'Enter' && e.code !== 'NumpadEnter') return

        //reset error
        this.barcodeErrorItem = ''
        this.trackNumErrorItem = ''

        let link = ''
        let requestType = ''
        let queryString = ''

        switch (type) {
          case 'barcode': {
            requestType = 'fetchSearchBarcode'
            queryString = `?scan_number=${this.barcode}`
            break
          }
          case 'track': {
            requestType = 'fetchSearchTrack'
            queryString = `?tracking_number=${this.trackNum}&exact=1`
            break
          }
        }

        this.$store.dispatch(requestType, queryString).then((response) => {

          let respData = this.getRespData(response)

          if (respData.found_in_consolidations.length) {
            let item = respData.found_in_consolidations[0]
            console.log('conso');
            link = this.$store.getters.GET_PATHS.ordersConsolidationEditing + '/' +
                this.getConsolidationTypeById(item.delivery_service.id).name + '/' + item.object.id
          }
          else if (respData.found_in_orders_express.length) {
            let item = respData.found_in_orders_express[0]
            console.log('express');
            link = this.$store.getters.GET_PATHS.expressOrderEdit + '/' +
                this.getExpressTypeById(item.delivery_service.id).name + '/' + item.object.id
          }
          else if (respData.found_in_orders_fbm.length) {
            let item = respData.found_in_orders_fbm[0]
            console.log('fbm');
            link = this.$store.getters.GET_PATHS.ordersFBM + '/' + this.getOrderFBMTypeById(item.object.type).name + '/' + item.object.id
          }
          else if (respData.found_in_orders_inbound.length) {
            let item = respData.found_in_orders_inbound[0]
            console.log('inb');
            link = this.$store.getters.GET_PATHS.ordersShipmentWarehouse + '/' +
                this.getOrderType(item.object.type, item.object.return).value + '/' + item.object.id
          }

          if (link !== '') {
            window.open(`${window.location.origin}${link}`, '_blank');
          }
          else if (type === 'barcode') {
            this.barcodeErrorItem = this.barcode
          }
          else if (type === 'track') {
            this.trackNumErrorItem = this.trackNum
          }

        }).catch(error => this.createErrorLog(error))

      }
    },
  }
</script>

<style scoped>

</style>
