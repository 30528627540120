<template>
  <div class="user-info-admin">
    <div class="user-info-admin__inner">
      <div class="user-info-admin__caption">
        {{$t('express_Created.localization_value.value')}}
      </div>
      <div class="user-info-admin__large">
        {{ new Date() | moment("DD MMM, YYYY") }}
      </div>
    </div>



  </div>
</template>

<script>


  export default {
    name: "UserInfo",
    components: {
    },

    data(){
      return{
        isModalHistoryOfChanges: false,
      }
    },

    methods:{
      changeHistoryOfChanges(val){
        this.isModalHistoryOfChanges= val
      },
    }
  }
</script>

<style scoped>
  .user-info-admin__large{
    font-size: 30px;
    line-height: normal;
  }
</style>
