<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
              class="clear-992"
              :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <UserInfo/>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.dashboard"
                             class="detail-page__head-back"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_goToDashboard'])"></div>
                  <LinkBack
                          :value="$t('common_goToDashboard.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['product_CustomProductThermalPrint'])"></div>
              {{$t('product_CustomProductThermalPrint.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['product_ThermalPrintPleaseFill'])"></div>
              {{$t('product_ThermalPrintPleaseFill.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <form class="order-create__section">
            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['product_ThermalPrintName'])"></div>
                <DefaultInput
                        :label="$t('product_ThermalPrintName.localization_value.value')"
                        v-bind:class="{'ui-no-valid': TP.validation.name}"
                        :error="TP.validation.name"
                        :errorTxt="$t(`${TP.validationTranslate.name}.localization_value.value`)"
                        v-model="TP.data.name"
                />
              </div>
              <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['product_ThermalPrintUPC'])"></div>
                <DefaultInput
                        :label="$t('product_ThermalPrintUPC.localization_value.value')"
                        v-bind:class="{'ui-no-valid': TP.validation.upc}"
                        :error="TP.validation.upc"
                        :errorTxt="$t(`${TP.validationTranslate.upc}.localization_value.value`)"
                        v-model="TP.data.upc"
                />
              </div>
              <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['product_ThermalPrintQuantity'])"></div>
                <InputQuantity
                        :label="$t('product_ThermalPrintQuantity.localization_value.value')"
                        v-bind:class="{'ui-no-valid': TP.validation.quantity}"
                        :error="TP.validation.quantity"
                        :errorTxt="$t(`${TP.validationTranslate.quantity}.localization_value.value`)"
                        :value="TP.data.quantity"
                        @change="changeQuantity"
                />
              </div>
              <div class="custom-col custom-col--66 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['product_ThermalPrintSKU'])"></div>
                <DefaultInput
                        :label="$t('product_ThermalPrintSKU.localization_value.value')"
                        v-bind:class="{'ui-no-valid': TP.validation.sku}"
                        :error="TP.validation.sku"
                        :errorTxt="$t(`${TP.validationTranslate.sku}.localization_value.value`)"
                        v-model="TP.data.sku"
                />
              </div>
            </div>
          </form>
        </template>
        <template slot="footer">
          <div class="order-create__footer"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.dashboard"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto">
              <MainButton
                      class="order-create__footer-btn-i"
                      v-bind:class="{'disabled-btn' : $store.getters.getProductsBtn}"
                      :value="$t('common_create.localization_value.value')"
                      @click.native="$emit('save')"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import UserInfo from "./components/UserInfo/UserInfo";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import InputQuantity from "../../../../../UI/inputs/InputQuantity/InputQuantity";

  export default {
    name: "ThermalPrintAdmin",
    components: {
      DefaultInput,
      MainButton,
      CardLeftBlock,
      UserInfo,
      CardRightBlock,
      LinkBack,
      InputQuantity,
    },

    props: {
      TP: Object,
    },

    data() {
      return {}
    },

    methods: {
      changeQuantity(val) {
        this.TP.setQuantity(val)
      },
    },
  }
</script>

<style scoped>

</style>
