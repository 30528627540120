<template>
  <ThermalPrintAdmin
          :TP="TP"
          @save="save"
  />
</template>

<script>
  import ThermalPrintAdmin from "./ThermalPrintAdmin/ThermalPrintAdmin";
  import {ThermalPrint} from "../../models/ThermalPrint";

  export default {
    name: "ThermalPrint",
    components: {
      ThermalPrintAdmin,
    },

    data() {
      return {
        TP: new ThermalPrint(),
      }
    },

    methods: {
      save() {
        if(!this.TP.validationThermalPrint()){
          return
        }

        let data = this.TP.prepareSave()

        let routeData = this.$router.resolve({ path: this.$store.getters.GET_PATHS.generatePdfFile + '/thermalPrint', query: {
          'name': data['name'],
          'upc': data['upc'],
          'sku': data['sku'],
          'quantity': data['quantity'],
          }})

        window.open(routeData.href, '_blank');
      },
    }
  }
</script>

<style scoped>

</style>
