<template>
  <ScanBarcodeAdmin

  />
</template>

<script>
  import ScanBarcodeAdmin from "./ScanBarcodeAdmin/ScanBarcodeAdmin";

  export default {
    name: "ScanBarcode",
    components: {
      ScanBarcodeAdmin,
    },

    data() {
      return {
      }
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>
