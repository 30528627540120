import {checkValid} from "../../../globalModels/functions/ModelsValidate";

export function ThermalPrint() {

  this.checkValid = checkValid

  this.data = {
    name: '',
    upc: '',
    sku: '',
    quantity: 0,
  }


  this.validation = {
    name: false,
    upc: false,
    sku: false,
    quantity: false,
  }

  this.validationTranslate = {
    name: '',
    upc: '',
    sku: '',
    quantity: '',
  }

  this.validationTxt = {
    name: false,
    upc: false,
    sku: false,
    quantity: false,
  }

  /**
   * Getters
   */
  this.getName = () => {
    return this.data.name
  }
  this.getUps = () => {
    return this.data.upc
  }
  this.getSku = () => {
    return this.data.sku
  }
  this.getQuantity = () => {
    return this.data.quantity
  }

  /**
   * Setters
   */
  this.setName = (val) => {
    this.data.name = val
  }
  this.setUps = (val) => {
    this.data.upc = val
  }
  this.setSku = (val) => {
    this.data.sku = val
  }
  this.setQuantity = (val) => {
    this.data.quantity = val
  }
}

/**
 * Functions
 */

ThermalPrint.prototype.resetData = function() {
  this.setName('')
  this.setUps('')
  this.setSku('')
  this.setQuantity(0)
}


/**
 * Prepare Data
 */

ThermalPrint.prototype.prepareSave = function() {
  return {
    'name': this.getName(),
    'upc': this.getUps(),
    'sku': this.getSku(),
    'quantity': this.getQuantity(),
  }
}


/**
 * Validations
 */

ThermalPrint.prototype.validationThermalPrint = function() {
  let validationItems = {
    name: this.getName(),
    upc: this.getUps(),
    sku: this.getSku(),
    quantity: parseInt(this.getQuantity()),
  }

  let validationOptions = {
    name: {type: ['latin','empty']},
    upc: {type: ['latin','empty']},
    sku: {type: ['latin', 'empty']},
    quantity: {type: ['not-zero']},
  }

  return this.checkValid(validationItems, validationOptions)
}
